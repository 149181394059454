body {
  max-width: 100vw;
  overflow-x: hidden;
}

#app {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  min-height: 100vh;
}

.headerRounded {
  height: 60vw;
  width: 60vw;
  border-bottom-left-radius: 100vw;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  @include media-breakpoint-up(sm) {
    height: 55vw;
    width: 55vw;
  }
  @include media-breakpoint-up(md) {
    height: 50vw;
    width: 50vw;
  }
  @include media-breakpoint-up(lg) {
    height: 55vw;
    width: 50vw;
  }
  @include media-breakpoint-up(xl) {
    height: 100vh;
  }

  .headerRounded-background {
    background-image: var(--background-jpg);

    @supports (
      background-image: url('data:image/webp;base64,UklGRhYAAABXRUJQVlA4WAoAAAAQAAAAEAEAAwAABAAQAAQAAAAwACAA=')
    ) {
      background-image: var(--background-webp);
    }

    @supports (
      background-image: url('data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAG1pZjFoYXZpZgAAACZtZGF0EgAKBxkAPw==')
    ) {
      background-image: var(--background-avif);
    }

    width: 116%;
    height: 116%;
    top: -4%;
    right: -8%;
    position: absolute;
    //transform: translateX(50%);
    overflow: hidden;
    background-size: cover !important;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background: $black;
      opacity: 0.5;
      position: absolute;
    }
  }
}

.img-01 {
  display: block;
  border-radius: $border-radius-secondary;
  overflow: hidden;
}

.ico-01 {
  width: 100%;
}

.ico-02 {
  background: $color-primary;
  border-radius: $border-radius-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  width: px2rem(50);
  height: px2rem(50);
  padding: px2rem(10);
  flex-shrink: 0;
  @include media-breakpoint-up(sm) {
    width: px2rem(65);
    height: px2rem(65);
    padding: px2rem(12.5);
  }
  @include media-breakpoint-up(xl) {
    width: px2rem(75);
    height: px2rem(75);
    padding: px2rem(15);
  }

  svg {
    width: 100%;
    height: 100%;

    * {
      fill: $white;
    }
  }
}

.ico-03 {
  height: px2rem(100);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60%;
    height: 60%;
    @include media-breakpoint-up(sm) {
      width: 80%;
      height: 80%;
    }
  }
}

.qr-code {
  width: 50%;
  margin: auto;
  @include media-breakpoint-up(sm) {
    width: 50%;
    height: 100%;
    z-index: 99;
  }
  @include media-breakpoint-up(lg) {
    width: 75%;
    height: 100%;
    //z-index: 99;
    //margin: -12px;
    @include media-breakpoint-up(xl) {
      width: 50%;
    }
  }

  img {
    width: 100%;
  }
}

.index-mobile {
  text-align: center;
  position: relative;
  @include media-breakpoint-up(sm) {
    margin-bottom: 0px;
  }

  .index-mobile-wrapper {
    left: 0;
    width: 150%;
    margin-top: -10%;
    transform: translateX(-20%) translateY(0%);
    @include media-breakpoint-up(sm) {
      margin-top: 0%;
      width: 150%;
      transform: translateX(-20%) translateY(10%);
    }
    @include media-breakpoint-up(md) {
      width: 225%;
      transform: translateX(-10%) translateY(5%);
    }
    @include media-breakpoint-up(lg) {
      width: 225%;
      transform: translateX(-25%) translateY(5%);
    }
    @include media-breakpoint-up(xl) {
      width: 190%;
      transform: translateX(-30%) translateY(5%);
    }

    .index-mobile-animate {
      animation: animatePhone;
      animation-duration: $animation-primary;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      backface-visibility: hidden;

      .index-mobile-wrapper-phone {
        z-index: 9;
        position: relative;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        transform: translateZ(0);
      }

      .img-mobile-phone {
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        transform: translateZ(0);
      }

      .index-mobile-wrapper-screen {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        perspective: 200px;

        picture {
          display: contents;
        }

        .img-mobile-screen {
          height: 100%;
          border-radius: 20px;
          position: absolute;
          top: -50%;
          left: 50%;
          transform: skewX(-25deg) rotateX(-1.05deg) translateX(-3%) translateY(48%) skewY(0deg) rotateY(-4.8deg)
            scaleX(0.69) scaleY(0.68);
          @include media-breakpoint-up(sm) {
            border-radius: 20px;
            position: relative;
            top: auto;
            left: auto;
            transform: skewX(-24deg) rotateX(6.95deg) translateX(-3px) translateY(-20px) skewY(0deg) rotateY(-2.8deg)
              scaleX(0.69) scaleY(0.68);
          }
          @include media-breakpoint-up(md) {
            border-radius: 20px;
            transform: skewX(-24deg) rotateX(3.95deg) translateX(-5px) translateY(-22px) skewY(0deg) rotateY(-2.8deg)
              scaleX(0.69) scaleY(0.68);
          }
          @include media-breakpoint-up(lg) {
            border-radius: 20px;
            transform: skewX(-24deg) rotateX(3.95deg) translateX(-6px) translateY(-28px) skewY(0deg) rotateY(-2.8deg)
              scaleX(0.69) scaleY(0.68);
          }
          @include media-breakpoint-up(xl) {
            border-radius: 35px;
            transform: skewX(-24deg) rotateX(2.95deg) translateX(-8px) translateY(-28px) skewY(0deg) rotateY(-2.8deg)
              scaleX(0.69) scaleY(0.68);
          }
        }
      }
    }

    .index-mobile-wrapper-shadow {
      .img-mobile-shadow {
        animation: animatePhoneShadow;
        animation-duration: $animation-primary;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        position: absolute;
        width: 100%;
        height: 100%;
        top: px2rem(-4);
        z-index: -1;
        left: 0;
      }
    }
  }
}

.section {
  position: relative;
  overflow: hidden;
  z-index: 1;

  &.section-header {
    @include media-breakpoint-up(xl) {
      min-height: 100vh;
      height: 100vh;
      display: flex;
      align-items: center;
    }
  }

  &.section-lg {
    padding: 10vw 0px;
    @include media-breakpoint-up(sm) {
      padding: 5vw 0px;
    }

    &.section-lg-content {
      padding: 10vw 15px;
      @include media-breakpoint-up(sm) {
        padding: 5vw 5vw;
      }
    }
  }

  &.section-md {
    padding: 10vw 0px;
    @include media-breakpoint-up(sm) {
      padding: 3vw 0px;
    }

    &.section-md-content {
      padding: 10vw 15px;
      @include media-breakpoint-up(sm) {
        padding: 3vw 3vw;
      }
    }
  }

  &.section-xs {
    padding: 6vw 0px;
    @include media-breakpoint-up(sm) {
      padding: 1vw 0px;
    }
  }
}

.table-benefits {
  overflow: hidden;
  margin: 0px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.5px solid $white;
    z-index: 1;
    pointer-events: none;
  }

  .table-benefits-item {
    padding: 0px;
    border: 0.5px solid $color-secondary-lighter;

    .table-benefits-content {
      padding: px2rem(15) px2rem(10);
      @include media-breakpoint-up(sm) {
        padding: px2rem(30) px2rem(20);
      }

      > * {
        padding: px2rem(5);
        @include media-breakpoint-up(sm) {
          padding: px2rem(10);
        }
      }
    }
  }
}

.subpage-header {
  overflow: hidden;
  padding: 15px 15px 00px 15px;
  background: $white;
  border-radius: $border-radius-primary $border-radius-primary 0px 0px;
  box-shadow: $box-shadow-primary;
  @include media-breakpoint-up(sm) {
    box-shadow: none;
    border-radius: 0px;
    padding: 0px;
    background: transparent;
  }
}

.vue-map-container {
  height: 35vh;
  width: 100%;
  @include media-breakpoint-up(sm) {
    height: 100%;
  }
}

.map-marker-bg {
  background: $color-primary;
  height: px2rem(50);
  width: px2rem(50);
  border-radius: $border-radius-secondary;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60%;
    height: 60%;
  }
}

.aspectRation16x9 {
  @include aspectRation(16, 9);
  display: block;
  overflow: hidden;

  .inner-box {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

.banner-primary {
  position: relative;
  display: block;
  overflow: hidden;
  //border-radius: $border-radius-primary;
  //box-shadow: $box-shadow-primary;

  .inner-box {
    transition: $transition-out;
    will-change: transform;

    &:after {
      content: '';
      width: 150%;
      height: 150%;
      border-radius: $border-radius-secondary;
      background: $black;
      position: absolute;
      top: -25%;
      left: -25%;
      transition: $transition-out;
      z-index: 9;
      opacity: 0.25;
    }

    img {
      position: absolute;
      width: 100%;
    }
  }

  &:hover {
    .inner-box {
      transition: $transition-in;
      transform: scale(1.1);

      &:after {
        height: 0%;
        opacity: 0;
        transition: $transition-in;
      }
    }
  }
}

.content {
  min-height: 35vh;
  margin-top: -5px;
  @include media-breakpoint-up(sm) {
    margin-top: 0px;
  }

  .loaded {
    > div {
      > h1 {
        &:first-child {
          margin-top: 0px;
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    color: $color-heading !important;
  }

  h1 {
    font-size: px2rem($default-font-size * 1.5);
    margin: px2rem(40) px2rem(0) px2rem(25) px2rem(0);
  }

  h2 {
    font-size: px2rem($default-font-size * 1.4);
    margin: px2rem(40) px2rem(0) px2rem(25) px2rem(0);
  }

  h3 {
    font-size: px2rem($default-font-size * 1.3);
    margin: px2rem(40) px2rem(0) px2rem(25) px2rem(0);
  }

  h4 {
    font-size: px2rem($default-font-size * 1.2);
    margin: px2rem(40) px2rem(0) px2rem(25) px2rem(0);
  }

  h5 {
    font-size: px2rem($default-font-size * 1.1);
    margin: px2rem(40) px2rem(0) px2rem(25) px2rem(0);
  }

  * + h3 {
    margin-top: px2rem(50);
  }

  ol {
    @extend .list-unstyled;
    @extend .list-numbered;

    > li {
      align-items: start;
      margin-top: px2rem(28);

      &:before {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: px2rem(-8);
      }
    }

    + p {
      margin-top: px2rem(30);
    }
  }

  ul {
    &:not(.list-check) {
      @extend .list-unstyled;
      @extend .list-primary;

      li {
        &:before {
          top: px2rem(12);
        }
      }

      + p {
        margin-top: px2rem(30);
      }
    }
  }

  .link,
  a {
    &:not(.btn-primary) {
      &:not([href$='#']) {
        @extend .btn-secondary;
      }
    }
  }
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.snow-wrap {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1 !important;
}

.llp-face-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: relative;
}

.a {
  fill: none;
}

#logo {
  position: relative;
  display: block;
  cursor: pointer;
  height: 50vh;
  width: 50vw;
}

::-webkit-scrollbar {
  width: px2rem(10);
}

::-webkit-scrollbar-track {
  border-radius: $border-radius-primary;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba($color-secondary-darker, 0.75);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba($color-secondary-darker, 0.5);
}

.badge {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 35%;
  }
}
